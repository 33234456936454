<template>
    <v-dialog max-width="500" class="pa-0 ma-9" v-model="$store.state.foater.forms.pay_off">
        <v-form lazy-validation v-model="valid" ref="form">
            <v-card :loading="loading" elevation="6"  max-width="800" class="ma-auto">
                <v-card-title class="primary"  style="direction: rtl;color: white">
                    <v-btn icon @click="$store.state.foater.forms.pay_off=false">
                        <v-icon large color="white">mdi-close</v-icon>
                    </v-btn>
                    <span class="mr-2 f18">تسديد فاتورة</span>

                </v-card-title>
                <v-divider />
                <v-card-text >

                    <v-row  class="ma-0">
                        <v-col cols="12">
                            <p class="f16b">
                                {{fatora.cost_name}}
                            </p>
                            <v-divider/>
                        </v-col>
                        <v-col cols="12" md="12">
                            <v-text-field-money v-model="fatora.carry"  :properties="{readonly:true,outlined:true,'prepend-inner-icon':'mdi-currency-usd'}" :options="{precision:0}" label="الدين"/>
                        </v-col>
                        <v-col cols="12" md="12">
                            <v-text-field-money  @input="calc" @keyup="calc" @change="calc"   v-model="fatora.new_wasel" :properties="{rules:req,outlined:true,'prepend-inner-icon':'mdi-currency-usd-off'}" :options="{precision:0}" label="الواصل"/>
                        </v-col>
                        <v-col cols="12" md="12">
                            <v-text-field-money  v-model="fatora.new_carry"  :properties="{readonly:true,outlined:true,'prepend-inner-icon':'mdi-cash'}" :options="{precision:0}" label="المتبقي"/>
                        </v-col>

                        <v-col  cols="12" md="12">
                            <v-sheet class="pa-1" outlined  label="تاريخ الانتهاء" prepend-inner-icon="mdi-calendar-month-outline" >
                                <p class="f16">تاريخ الاستلام</p>
                                <v-divider/>
                                <input v-model="fatora.fatora_date"  style="border: 0px;width: 100%" type="datetime-local">
                            </v-sheet>
                        </v-col>


                        <v-col cols="12">
                            <v-textarea v-model="fatora.fatora_notes"  height="80" outlined label="الملاحظات" prepend-inner-icon="mdi-information"/>
                        </v-col>

                    </v-row>

                </v-card-text>
                <v-divider/>
                <v-card-actions style="direction: rtl">
                    <v-btn @click="pay_off" color="primary" :loading="loading" large>
                        <v-icon>mdi-check</v-icon>
                        <span class="f16 pa-2">حفظ</span>
                    </v-btn>

                </v-card-actions>

            </v-card>
        </v-form>

    </v-dialog>
</template>

<script>
    import moment from "moment";

    export default {
        name: "PayOffFatora",
        data(){
            return{
                valid:true,
                loading:false,
                fatora:{
                    carry:'',
                    fatora_date:moment(new Date()).format('YYYY-MM-DDTHH:mm'),
                    cost_id: '',
                    cost_name: '',
                    new_wasel:'',
                    new_carry:'',
                    fatora_notes:'',
                    fatora_SaveDate:moment(new Date()).format('YYYY-MM-DD hh:mm:ss')
                },
                req:[v=>!!v || 'يرحى ادخال مبلغ'],
            }
        },
        methods:{
            calc()
            {
                var debt = this.null2zero(this.fatora.carry);
                var wasel = this.null2zero(this.fatora.new_wasel);


                var new_carry = debt - wasel ;
                this.fatora.new_carry = new_carry;


            },
            null2zero(num)
            {
                if(num== null || num == undefined || num == "")
                {
                    return 0;
                }else{
                    return parseFloat(num);
                }
            },
            async pay_off()
            {
                if(this.$refs.form.validate())
                {
                    this.fatora.fatora_date = moment(this.fatora.fatora_date).format('YYYY-MM-DD hh:mm:ss');
                    this.loading = true;
                    await  this.$axios.post('api/pay-off-fatora',this.fatora).then(res=>{
                        this.$store.commit("GET_FATORA_DEBTS_TO_US");
                        this.$refs.form.reset();
                        this.$store.state.foater.forms.pay_off = false;
                        this.$fire({
                            title: "نجح",
                            text: res.data.msg,
                            type: "success",
                            timer: 2000
                        });
                    }).catch(err=>{
                        console.log(err)
                    }).finally(fin=>{
                        this.loading = false;
                        this.fatora.fatora_date = moment(this.fatora.fatora_date).format('YYYY-MM-DDTHH:mm');
                    })
                }

            }
        },
        computed:{
            get_fatora:function () {
                return this.$store.state.foater.target
            }
        },
        watch:{
            get_fatora:function (new_fatora) {


                    this.fatora = new_fatora;
                    this.fatora.fatora_date = moment(new Date()).format('YYYY-MM-DDTHH:mm');
                    this.fatora.fatora_SaveDate = moment(new Date()).format('YYYY-MM-DD hh:mm:ss')
                    console.log(new_fatora)

            }
        }
    }
</script>

<style scoped>

</style>