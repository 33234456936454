<template>
    <v-container>
        <v-dialog max-width="500" class="pa-0 ma-9" v-model="$store.state.foater.forms.print_fatora">
        <v-card class="pa-0">
            <v-card-title>
                <v-icon>mdi-printer</v-icon>
                <span class="f16 mr-2">طباعة فاتورة</span>

                <v-spacer/>
                <v-btn icon @click="$store.state.foater.forms.print_fatora=false">
                    <v-icon>mdi-close</v-icon>
                </v-btn>
            </v-card-title>
            <v-divider/>
            <v-card-text class="pa-4">
                <div id="printMe" class="elevation-2">

                    <table class="text-center" style="direction: rtl;width: 100%;height: 100%;border: 1px solid black !important;">
                        <thead>
                        <tr  style="background-color: gainsboro">
                            <th colspan="2">
                                <div class="pa-2 grey white--text">
                                    <span>فاتورة : </span> <span>{{fatora.fatora_type | fatora_type}}</span>

                                </div>
                            </th>
                        </tr>
                        <tr>
                            <th>
                                <span>رقم الفاتورة : </span> <span>{{fatora.fatora_number}}</span>
                            </th>
                            <th>
                                <span>تاريخ الفاتورة : </span> <span>{{fatora.fatora_date |datefilter}}</span>
                            </th>

                        </tr>

                        <tr>
                            <th>
                                الزبون
                            </th>
                            <th>
                                {{fatora.cost_name}}
                            </th>
                        </tr>
                        </thead>

                    </table>
                    <table class="text-center mt-4 list-table" style="border-collapse:collapse;direction: rtl;width: 100%;height: 100%;border: 1px solid black !important;">
                        <tr>
                            <th style="border: 1px solid black;background-color: gainsboro">ت</th><th style="border: 1px solid black;background-color: gainsboro">المادة</th><th style="border: 1px solid black;background-color: gainsboro">الكمية</th><th style="border: 1px solid black;background-color: gainsboro">سعر المفرد</th><th style="border: 1px solid black;background-color: gainsboro">الاجمالي</th>
                        </tr>
                        <tr v-for="(li , i ) in list" :key="'index_i_'+i">
                            <td class="text-center" style="border: 1px solid black;">{{list.indexOf(li)+1}}</td><td style="border: 1px solid black" class="text-center">{{li.item_name}}</td><td style="border: 1px solid black" class="text-center">{{li.item_fatora_count}}</td><td style="border: 1px solid black" class="text-center">{{li.item_fatora_price | money_iq}}</td><td style="border: 1px solid black" class="text-center">{{ parseFloat(li.item_fatora_price)*parseFloat(li.item_fatora_count) | money_iq}}</td>
                        </tr>
                    </table>
                    <table style="width: 100%;direction: rtl;border-collapse: collapse;border: 1px solid black">
                        <tr style="border: 1px solid black">
                            <th style="border: 1px solid black">المجموع</th><td style="border: 1px solid black">{{fatora.fatora_total_my | money_iq}}</td><th style="border: 1px solid black">الواصل</th><td style="border: 1px solid black">{{fatora.fatora_wasel_him | money_iq}}</td><th style="border: 1px solid black">المتبقي</th><td style="border: 1px solid black"> {{parseFloat(fatora.fatora_total_my) - parseFloat(fatora.fatora_wasel_him) |money_iq}}</td>
                        </tr>
                    </table>
                    <table class="mt-4" style="width: 100%;direction: rtl;border-collapse: collapse;border: 1px solid black">
                        <tr>
                            <th style="border: 1px solid black">مسجل الوصل</th><td style="border: 1px solid black">{{fatora.fatora_user}}</td><th style="border: 1px solid black">الملاحظات</th><td style="border: 1px solid black">{{fatora.fatora_notes}}</td>
                        </tr>
                    </table>

                </div>

            </v-card-text>
            <v-divider/>
            <v-card-actions>
                <v-btn dark color="cardtitleblack" block @click="print">
                    <v-icon>mdi-printer</v-icon>
                    <span class="mr-2 f16">طباعة الفاتورة</span>
                </v-btn>
            </v-card-actions>
        </v-card>
        </v-dialog>
    </v-container>
</template>

<script>
    import moment from "moment";
    import $ from 'jquery'
    export default {
        name: "PrintFatora",
        filters:{
            datefilter: function (value) {
                if (value != null && value != '' && value != undefined && value != 'NaN') {
                    return moment(value).format('YYYY-MM-DD hh:mm A')
                }
            },
            money_iq:function(value){
                if(value != null || value != 0 || value !=undefined)
                {
                    value = Math.trunc(value);
                    return value.toLocaleString('en-IQ')
                }
            },
            fatora_type:function (value) {
                if(value ==1)
                {
                    return "شراء";
                }
                if(value ==11)
                {
                    return "تسديد شراء";
                }
                if(value ==2)
                {
                    return "بيع";
                }
                if(value ==22)
                {
                    return "تسديد مبيع";
                }

            }
        },
        data(){
            return{
                fatora:this.$store.state.foater.target,
                list:[]
            }
        },
        methods:{
            async print () {
                // Pass the element id here
               await this.$htmlToPaper('printMe');
               //  var restorepage = $('body').html();
               //  var printcontent = $('#printMe').clone();
               //  $('body').empty().html(printcontent);
               //  window.print();
               //  $('body').html(restorepage);
            },
            get_fatora_to_print()
            {
                this.$axios.post('api/get-fatora-sell-to-print',this.fatora).then(res=>{
                    console.log(res.data)
                    this.list = res.data.fatora
                })
            }
        },
        computed:{
            get_fatora:function () {
                return this.$store.state.foater.target
            }
        },
        watch:{
            get_fatora:function (new_fatora) {
                this.fatora = new_fatora;
                this.get_fatora_to_print()
            }
        },
        created(){
            this.get_fatora_to_print()
        }

    }
</script>

<style scoped>

    table{
        border-collapse: collapse;
    }
   tr,th,td{
        border: 1px solid black !important;
       text-align: center;


    }
    *{
        font-size: 15px !important;
    }


@media print {
    *{
        font-size: 10px !important;
    }
    table{
        border-collapse: collapse;
    }
    tr,th,td{
        border: 1px solid black !important;
        text-align: center !important;


    }
}



</style>